import React, { Component } from "react";
import { alertActions, userActions } from "../../_actions";
import { connect } from "react-redux";
import "jspdf-autotable";
import LoadingOverlay from "react-loading-overlay";
import "./styles.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { MdOutlineContentCopy } from "react-icons/md";
import moment from 'moment';

class FindPassPhrase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      listSuccess: [],
      errorsUser: {},
      fieldsUser: {},
      isOpen: false,
      fieldsDetails: {},
      input1: "",
      input2: "",
      currentPage: 1,
      pageSize: 10,
      isDetailModule: true,
      isBalance: null
    };
  }

  componentDidMount() {
    this.props.dispatch(userActions.clearDataEVM());
    this.props.dispatch(userActions.clearData());

    const resData = {
      id: this.props.match.params.id,
      isBalance: this.state.isBalance,
      keyWord: "",
      pageNo: this.state.currentPage,
      sortBy: "createdAt",
      sortOrder: "desc",
      size: Number(this.state.pageSize),
    };


    console.log("this.props.match.params.id", this.props.match.params.id)
    if (this.props.match.params.id) {
      this.props.dispatch(userActions.getAddressBalanceById(resData));
    }
    window.scrollTo(0, 0);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.users.savePharasesSuccess) {
      return {
        ...nextProps,
      };
    } else {
      return {
        ...nextProps,
      };
    }
  }

  handleCloseModal = () => {
    this.props.dispatch(userActions.clearData());
    this.setState({ errorsUser: {}, fieldsUser: {} });
  };
  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber, }, () => {
      let offset = Math.ceil((this.state.currentPage - 1) * this.state.pageSize);
      this.setState({ offset: offset });
      const resData = {
        id: this.props.match.params.id,
        isBalance: this.state.isBalance,
        keyWord: "",
        pageNo: this.state.currentPage,
        sortBy: "createdAt",
        sortOrder: "desc",
        size: Number(this.state.pageSize),
      };

      this.props.dispatch(userActions.getAddressBalanceById(resData));

    });
  };

  renderPagination = () => {
    // this.props?.users?.addressBalanceById
    const { addressBalanceById } = this.props.users;
    const { total } = addressBalanceById
      ? addressBalanceById
      : {};
    const { currentPage, pageSize } = this.state;
    const totalPages = total ? Math.ceil(total / pageSize) : 0;

    if (totalPages <= 1) return null;

    let pages = [];
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, currentPage + 2);

    // Previous Button
    if (currentPage > 1) {
      pages.push(
        <button
          key="prev"
          onClick={() => this.handlePageChange(currentPage - 1)}
          className="px-4 py-2 mx-1 rounded bg-[#2E2E4E] hover:bg-[#CB9731]"
        >
          Previous
        </button>
      );
    }

    // Page Numbers
    if (startPage > 1) {
      pages.push(
        <span key="first" className="px-4 py-2 mx-1 rounded">
          1
        </span>
      );
      if (startPage > 2) {
        pages.push(
          <span key="dots-prev" className="px-2 py-2 mx-1 rounded">
            ...
          </span>
        );
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => this.handlePageChange(i)}
          className={`px-4 py-2 mx-1 rounded ${currentPage === i ? "bg-[#CB9731]" : "bg-[#2E2E4E]"
            }`}
        >
          {i}
        </button>
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pages.push(
          <span key="dots-next" className="px-2 py-2 mx-1 rounded">
            ...
          </span>
        );
      }
      pages.push(
        <button
          key={totalPages}
          onClick={() => this.handlePageChange(totalPages)}
          className="px-4 py-2 mx-1 rounded bg-[#2E2E4E] hover:bg-[#CB9731]"
        >
          {totalPages}
        </button>
      );
    }

    // Next Button
    if (currentPage < totalPages) {
      pages.push(
        <button
          key="next"
          onClick={() => this.handlePageChange(currentPage + 1)}
          className="px-4 py-2 mx-1 rounded bg-[#2E2E4E] hover:bg-[#CB9731]"
        >
          Next
        </button>
      );
    }

    return <div className="flex justify-center mt-4">{pages}</div>;
  };

  handleInputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    // this.setState({ [name]: value });
    let fieldsUser = this.state.fieldsUser;
    let errorsUser = this.state.errorsUser;
    fieldsUser[name] = value;
    errorsUser[name] = "";
    this.setState({ currentPage: 1 })
    const resData = {
      id: this.props.match.params.id,
      isBalance: this.state.isBalance,
      keyWord: value ? value : "",
      pageNo: 1,
      sortBy: "createdAt",
      sortOrder: "desc",
      size: Number(this.state.pageSize),
    };

    // console.log("this.props.match.params.id", this.props.match.params.id)
    if (this.props.match.params.id) {
      this.props.dispatch(userActions.getAddressBalanceById(resData));
    }

  };
  submitHandle = () => {
    // console.log('');
    if (this.validationCheck()) {
      const { passPhrase, index } = this.state.fieldsUser;
      const resData = {
        passPhrase: passPhrase,
        // replaceIndex: index
      };
      const resData2 = {
        keyWord: "",
        pageNo: 1,
        sortBy: "createdAt",
        sortOrder: "desc",
        size: 20,
      };

      this.props.dispatch(userActions.getValidPassPhrasesWithAddress(resData));
      this.props.dispatch(
        userActions.getValidPassPhrasesWithAddressList(resData2)
      );
    }
  };
  validationCheck = () => {
    let fieldsUser = this.state.fieldsUser;
    let errorsUser = {};
    let valid = true;

    if (!fieldsUser["passPhrase"] || fieldsUser["passPhrase"] === "") {
      errorsUser["passPhrase"] = `PassPhrase can't be empty.`;
      valid = false;
    }
    this.setState({ errorsUser: errorsUser });
    return valid;
  };

  inputChange = (e) => {

    this.setState({ pageSize: e.target.value }, () => {
      const resData = {
        id: this.props.match.params.id,
        isBalance: this.state.isBalance,
        keyWord: "",
        pageNo: this.state.currentPage,
        sortBy: "createdAt",
        sortOrder: "desc",
        size: Number(this.state.pageSize),
      };
      this.props.dispatch(userActions.getAddressBalanceById(resData));
    });
  }
  handleIsBalance = (e) => {
    // console.log('e.target.value', JSON.parse(e.target.value));
    this.setState({ isBalance: e && e.target && e.target.value ? JSON.parse(e.target.value) : null }, () => {
      const resData = {
        id: this.props.match.params.id,
        isBalance: this.state.isBalance,
        keyWord: "",
        pageNo: this.state.currentPage,
        sortBy: "createdAt",
        sortOrder: "desc",
        size: Number(this.state.pageSize),
      };
      console.log('resData :: =>', resData);
      this.props.dispatch(userActions.getAddressBalanceById(resData));
    });
  }
  handleIsDetails = (data) => {
    this.setState({ isDetailModule: false, field: data })
  }

  navigatePhraseDetails = (data) => {
    this.setState({ isOpen: true, fieldsDetails: data })
    // this.props.history.push(`/app/TwoMissingPassDetailsAddress/${data.id}`);
  };

  closeModal = () => {
    this.setState({ isOpen: false, fieldsDetails: {} })
  }


  render() {
    let { users } = this.props;

    const sumData = this.props?.users?.addressBalanceById?.sum;
    const { passPhrase, index } = this.state.fieldsUser;
    const {
      loading,
      addressBalanceById,
      validBtcPassPhrases,
      ValidPassPhrasesWithAddressList,
    } = users;
    let { list } = addressBalanceById ? addressBalanceById : {};
    let { isOpen, fieldsDetails } = this.state
    console.log('fieldsDetails ::: ', fieldsDetails);

    const copyToClipboard = (text) => {
      navigator.clipboard.writeText(text);
      alertActions.success("Copied successfully")
    };


    return (
      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text="Please wait..."
          />
        </div>

        <div className="relative z-0 flex-1 overflow-y-auto focus:outline-none bg_page">
          <section>
            <div className="overflow-hidden">
              <div className="overflow-y-auto">
                <div className="p-8 space-y-3 ">


                  <div className="grid w-full grid-cols-1 gap-5 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 lg:gap-6">
                    <div className="w-full px-7 py-7 dashboard-card rounded-xl divide-y-[1px] divide-white/30">
                      <div className="flex items-center justify-center w-full">
                        <div className="flex flex-col items-center justify-center">
                          <span className="text-xl md:text-2xl lg:text-3xl font-bold text-[#B5862B]">
                            Bnb Balance
                          </span>
                          <span className="text-xl font-bold text-white md:text-2xl lg:text-2xl">
                            {sumData?.bnbBalance}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="w-full px-7 py-7 dashboard-card rounded-xl divide-y-[1px] divide-white/30">
                      <div className="flex items-center justify-center w-full">
                        <div className="flex flex-col items-center justify-center">
                          <span className="text-xl md:text-2xl lg:text-3xl font-bold text-[#B5862B]">
                            Eth Balance
                          </span>
                          <span className="text-xl font-bold text-white md:text-2xl lg:text-2xl">
                            {sumData?.ethBalance}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="w-full px-7 py-7 dashboard-card rounded-xl divide-y-[1px] divide-white/30">
                      <div className="flex items-center justify-center w-full">
                        <div className="flex flex-col items-center justify-center">
                          <span className="text-xl md:text-2xl lg:text-3xl font-bold text-[#B5862B]">
                            Legacy Balance
                          </span>
                          <span className="text-xl font-bold text-white md:text-2xl lg:text-2xl">
                            {sumData?.legacyBalance}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="w-full px-7 py-7 dashboard-card rounded-xl divide-y-[1px] divide-white/30">
                      <div className="flex items-center justify-center w-full">
                        <div className="flex flex-col items-center justify-center">
                          <span className="text-xl md:text-2xl lg:text-3xl font-bold text-[#B5862B]">
                            Segwit Balance
                          </span>
                          <span className="text-xl font-bold text-white md:text-2xl lg:text-2xl">
                            {sumData?.segWitBalance}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col justify-center place-content-center">

                    <div className='flex items-center space-x-4'>

                      <div className="">
                        <label className='mr-2 text-sm text-gray-500'>Show:</label>
                        <div className="relative inline-block dropdown">
                          <div className="border border-gray-200 w-full py-1.5 inline-flex items-center">
                            <select onChange={(e) => this.inputChange(e)} className="w-full text-sm bg-white focus:outline-none" value={this.state.pageSize}>
                              <option value="10">10</option>
                              <option value="25">25</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='flex items-center space-x-4'>

                      <div className="">
                        <label className='mr-2 text-sm text-gray-500'>Balance Status:</label>
                        <div className="relative inline-block dropdown">
                          <div className="border border-gray-200 w-full py-1.5 inline-flex items-center">
                            <select
                              onChange={(e) => this.handleIsBalance(e)}
                              className="w-full text-sm bg-white focus:outline-none"
                            >
                              <option value="">Select</option>
                              <option value={false}>All</option>
                              <option value={true}>Balance Address</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className=" w-[35rem] mx-auto ">
                      <label className="text-[#CB9731]">Search</label>
                      <div className="my-2">
                        <input
                          type="text"
                          id="passPhrase"
                          name="passPhrase"
                          value={passPhrase ?? ""}
                          onChange={this.handleInputChange}
                          className="w-full p-2 border rounded"
                          placeholder="Enter Here"
                        />
                        {this.state.errorsUser["passPhrase"] ? (
                          <div className="text-xs italic text-red-500 invalid-feedback">
                            {this.state.errorsUser["passPhrase"]}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <section className="rounded-lg">
                    <div className="max-w-full overflow-auto">
                      <div className="text-xl font-bold text-center text-white">
                        {validBtcPassPhrases?.validCount ?? null}
                      </div>
                      <div className="inline-block min-w-full">
                        <section className="rounded-lg">
                          <div className="max-w-full overflow-auto">
                            <div className="inline-block min-w-full">
                              <table className="min-w-full bg-[#24243C] rounded-lg">
                                <thead className="text-center text-white uppercase ">
                                  PassPhrases
                                  <tr className="text-white bg-[#2E2E4E] text-sm font-semibold text-center uppercase rounded-t-lg">
                                    <th className="px-3 py-3 whitespace-nowrap">
                                      S/n
                                    </th>
                                    <th className="px-3 py-3 capitalize whitespace-nowrap">
                                      createdAt
                                    </th>
                                    <th className="px-3 py-3 capitalize whitespace-nowrap">
                                      passphrases
                                    </th>
                                    <th className="px-3 py-3 capitalize whitespace-nowrap">
                                      ethBalance
                                    </th>
                                    <th className="px-3 py-3 capitalize whitespace-nowrap">
                                      bnbBalance
                                    </th>
                                    <th className="px-3 py-3 capitalize whitespace-nowrap">
                                      segWitBalance
                                    </th>
                                    <th className="px-3 py-3 capitalize whitespace-nowrap">
                                      legacyBalance
                                    </th>
                                    <th className="px-3 py-3 capitalize whitespace-nowrap">
                                      nativeSegWitBalance
                                    </th>
                                    <th className="px-3 py-3 capitalize whitespace-nowrap">
                                      rippleBalance
                                    </th>
                                    <th className="px-3 py-3 capitalize whitespace-nowrap">
                                      solanaBalance
                                    </th>
                                    <th className="px-3 py-3 capitalize whitespace-nowrap">
                                      action
                                    </th>
                                  </tr>
                                </thead>
                                {this.props.match.params.id
                                  ?
                                  <tbody>
                                    {list &&
                                      list &&
                                      list.length >
                                      0
                                      ? list.map(
                                        (element, index) => (
                                          <tr className="rounded-lg text-xs text-center text-[#837BB0]">
                                            <td className="px-3 py-3 whitespace-nowrap">
                                              {this.state.offset + index + 1}
                                            </td>

                                            <td className="px-3 py-3 whitespace-nowrap">
                                              {element && element.createdAt ? moment(element.createdAt).format('DD-MM-YYYY HH:mm') : null}

                                            </td>
                                            <td className="flex gap-2 px-3 py-3 whitespace-nowrap">
                                              {element && element.passPhrase
                                                ? element.passPhrase.length > 20
                                                  ? `${element.passPhrase.substring(0, 20)}...`
                                                  : element.passPhrase
                                                : "-"}<MdOutlineContentCopy onClick={() => copyToClipboard(element.passPhrase)} className="cursor-pointer " />
                                            </td>

                                            <td className="px-3 py-3 whitespace-nowrap">
                                              {element && element.ethBalance
                                                ? element.ethBalance
                                                : "0"}
                                            </td>
                                            <td className="px-3 py-3 whitespace-nowrap">
                                              {element && element.bnbBalance
                                                ? element.bnbBalance
                                                : "0"}
                                            </td>
                                            <td className="px-3 py-3 whitespace-nowrap">
                                              {element && element.segWitBalance
                                                ? element.segWitBalance
                                                : "0"}
                                            </td>
                                            <td className="px-3 py-3 whitespace-nowrap">
                                              {element && element.legacyBalance
                                                ? element.legacyBalance
                                                : "0"}
                                            </td>
                                            <td className="px-3 py-3 whitespace-nowrap">
                                              {element && element.nativeSegWitBalance
                                                ? element.nativeSegWitBalance
                                                : "0"}
                                            </td>
                                            <td className="px-3 py-3 whitespace-nowrap">
                                              {element && element.rippleBalance
                                                ? element.rippleBalance
                                                : "0"}
                                            </td>
                                            <td className="px-3 py-3 whitespace-nowrap">
                                              {element && element.solanaBalance
                                                ? element.solanaBalance
                                                : "0"}
                                            </td>

                                            <td className="px-3 py-3 whitespace-nowrap">
                                              <span
                                                onClick={() =>
                                                  this.navigatePhraseDetails(
                                                    element
                                                  )
                                                }
                                                className="bg-[#20BFA9] hover:bg-[#B5862B] px-4 py-2 rounded text-white cursor-pointer"
                                              >
                                                More Details
                                              </span>
                                            </td>
                                          </tr>
                                        )
                                      )
                                      : null}
                                  </tbody>

                                  :

                                  <tbody>
                                    {ValidPassPhrasesWithAddressList &&
                                      ValidPassPhrasesWithAddressList.list &&
                                      ValidPassPhrasesWithAddressList.list.length >
                                      0
                                      ? ValidPassPhrasesWithAddressList.list.map(
                                        (element, index) => (
                                          <tr className="rounded-lg text-xs text-center text-[#837BB0]">
                                            <td className="px-3 py-3 whitespace-nowrap">
                                              {this.state.offset + index + 1}
                                            </td>
                                            <td className="px-3 py-3 whitespace-nowrap">
                                              {element && element.isCronTab
                                                ? element.isCronTab
                                                : 0}
                                            </td>
                                            <td className="px-3 py-3 whitespace-nowrap">


                                              {moment(new Date(parseInt(element && element.createdAt ? element.createdAt : "-"))).format("DD-MM-YYYY HH:mm")}
                                            </td>
                                            <td className="px-3 py-3 whitespace-nowrap">
                                              {element && element.passPhrase
                                                ? element.passPhrase
                                                : "-"}
                                            </td>
                                            <td className="px-3 py-3 whitespace-nowrap">
                                              <button
                                                onClick={() =>
                                                  this.navigatePhraseDetails(
                                                    element
                                                  )
                                                }
                                              ></button>
                                              Details
                                            </td>
                                          </tr>
                                        )
                                      )
                                      : null}
                                  </tbody>}

                              </table>
                              {this.renderPagination()}
                            </div>
                          </div>
                        </section>
                        {isOpen && (
                          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                            <div className="bg-[#24243C] rounded-lg p-4 max-w-3xl w-full">
                              <button onClick={this.closeModal} className="mb-4 text-right text-white">
                                Close
                              </button>
                              <div className="max-w-full overflow-auto">
                                <div className="inline-block min-w-full">
                                  <table className="min-w-full bg-[#24243C] rounded-lg">
                                    <thead className="text-center text-white uppercase">
                                      <tr className="text-white bg-[#2E2E4E] text-sm font-semibold text-center uppercase rounded-t-lg">
                                        <th className="px-3 py-3 whitespace-nowrap">Type</th>
                                        <th className="px-3 py-3 capitalize whitespace-nowrap">Address</th>
                                        <th className="px-3 py-3 capitalize whitespace-nowrap">Balance</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr className="rounded-lg text-xs text-center text-[#837BB0]">
                                        <td className="px-3 py-3 whitespace-nowrap">BNB</td>
                                        <td className="flex gap-2 px-3 py-3 whitespace-nowrap">{fieldsDetails?.ethAddress ?? '-'}</td>
                                        <td className="px-3 py-3 whitespace-nowrap">{fieldsDetails?.bnbBalance ?? 0}</td>
                                      </tr>
                                      <tr className="rounded-lg text-xs text-center text-[#837BB0]">
                                        <td className="px-3 py-3 whitespace-nowrap">ETH</td>
                                        <td className="flex gap-2 px-3 py-3 whitespace-nowrap">{fieldsDetails?.ethAddress ?? '-'}</td>
                                        <td className="px-3 py-3 whitespace-nowrap">{fieldsDetails?.ethBalance ?? 0}</td>
                                      </tr>
                                      <tr className="rounded-lg text-xs text-center text-[#837BB0]">
                                        <td className="px-3 py-3 whitespace-nowrap">SEG-WIT</td>
                                        <td className="flex gap-2 px-3 py-3 whitespace-nowrap">{fieldsDetails?.segWitAddress ?? '-'}</td>
                                        <td className="px-3 py-3 whitespace-nowrap">{fieldsDetails?.segWitBalance ?? 0}</td>
                                      </tr>
                                      <tr className="rounded-lg text-xs text-center text-[#837BB0]">
                                        <td className="px-3 py-3 whitespace-nowrap">LEGACY</td>
                                        <td className="flex gap-2 px-3 py-3 whitespace-nowrap">{fieldsDetails?.legacyAddress ?? '-'}</td>
                                        <td className="px-3 py-3 whitespace-nowrap">{fieldsDetails?.legacyBalance ?? 0}</td>
                                      </tr>
                                      <tr className="rounded-lg text-xs text-center text-[#837BB0]">
                                        <td className="px-3 py-3 whitespace-nowrap">NATIVE SEG-WIT</td>
                                        <td className="flex gap-2 px-3 py-3 whitespace-nowrap">{fieldsDetails?.nativeSegWitAddress ?? '-'}</td>
                                        <td className="px-3 py-3 whitespace-nowrap">{fieldsDetails?.nativeSegWitBalance ?? 0}</td>
                                      </tr>
                                      <tr className="rounded-lg text-xs text-center text-[#837BB0]">
                                        <td className="px-3 py-3 whitespace-nowrap">SOLANA</td>
                                        <td className="flex gap-2 px-3 py-3 whitespace-nowrap">{fieldsDetails?.solanaAddress ?? '-'}</td>
                                        <td className="px-3 py-3 whitespace-nowrap">{fieldsDetails?.solanaBalance ?? 0}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  {/* Add pagination if needed */}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </section>
        </div>

      </>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  console.log("usersusersusersusersusersusers", users);
  const { overview } = users ? users : {};
  const { setting } = overview ? overview : {};
  return {
    users,
    setting,
  };
}
export default connect(mapStateToProps)(FindPassPhrase);
